import { Alert, Badge, Box, Button, ContentLayout, Form, FormField, Header, Input, Modal, Select, SpaceBetween, Spinner, Table, Textarea } from "@cloudscape-design/components";
import { AppHeader } from "../../commons/header";
import React from "react";
import DatabaseService from "../../services/databaseservice";
import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";

export default function AdminGlossary() {
    const [visibleCreate, setVisibleCreate] = React.useState(false);
    const [visibleModify, setVisibleModify] = React.useState(false);
    const [visibleDelete, setVisibleDelete] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const [term, setTerm] = React.useState("");
    const [category, setCategory] = React.useState<OptionDefinition>({ label: "Cost Management", value: "Cost Management" });
    const [explanation, setExplanation] = React.useState("");
    const [abbreviation, setAbbreviation] = React.useState("");
    const [synonyms, setSynonyms] = React.useState("");
    const [glossaryID, setGlossaryID] = React.useState("");

    const options = [
        { label: "General", value: "0" },
        { label: "Cost Management", value: "1" },
        { label: "Security Baseline", value: "2" },
        { label: "Identity Baseline", value: "3" },
        { label: "Resource Consistency", value: "4" },
        { label: "Deployment Acceleration", value: "5" },
    ]

    function openCreateModal() {
        setExplanation("")
        setTerm("")
        setCategory({ label: "Cost Management", value: "Cost Management" })
        setAbbreviation("")
        setSynonyms("")
        setGlossaryID("")
        setVisibleCreate(true)
    }

    function generateSynonyms(synonyms: any): React.ReactNode {
        return synonyms.map((e: string | undefined) => <Badge>{e}</Badge>)
    }

    return (
        <ContentLayout
            header={
                <AppHeader
                    breadcrumbs={[
                        { text: "CloudFellow", href: "/" },
                        { text: "Administration Area", href: "/" },
                        { text: "Glossar", href: "/glossary" }
                    ]}
                    header={"Glossar"}
                    description={"Hier kannst du das Glossar bearbeiten."}
                    actions={null}
                />
            }>
            <Table
                wrapLines
                columnDefinitions={[
                    {
                        id: "term",
                        header: "Term",
                        cell: e => e.data().term,
                        width: 130,
                        isRowHeader: true,
                    },
                    {
                        id: "category",
                        header: "Kategorie",
                        cell: e => (<Badge>{e.data().category.label}</Badge>),
                        width: 160
                    },
                    {
                        id: "explanation",
                        header: "Erklärung",
                        cell: e => (<Box display="block">{e.data().explanation}</Box>),
                        width: 400
                    },
                    {
                        id: "abbreviation",
                        header: "Abkürzung",
                        cell: e => (<Badge>{e.data().abbreviation}</Badge>),
                        width: 130
                    },
                    {
                        id: "synonyms",
                        header: "Synonyme",
                        cell: e => (<SpaceBetween size={"xxxs"}>{generateSynonyms(e.data().synonyms)}</SpaceBetween>),
                        width: 130
                    },
                    {
                        id: "actions",
                        header: "Actions",
                        width: 150,
                        cell: item => <SpaceBetween direction="horizontal" size={"s"}>
                            <Button onClick={() => {
                                setTerm(item.data().term)
                                setCategory(item.data().category)
                                setExplanation(item.data().explanation)
                                setAbbreviation(item.data().abbreviation)
                                setSynonyms(item.data().synonyms.toString())
                                setGlossaryID(item.id)
                                setVisibleModify(true)
                            }}>Modify</Button>
                            <Button onClick={() => {
                                setGlossaryID(item.id)
                                setTerm(item.data().term)
                                setVisibleDelete(true)
                            }
                            }>Delete</Button>
                        </SpaceBetween>
                    }
                ]}
                items={DatabaseService.getGlossaryTerms()}
                loadingText="Loading resources"
                sortingDisabled
                empty={
                    <Box
                        margin={{ vertical: "xs" }}
                        textAlign="center"
                        color="inherit"
                    >
                        <SpaceBetween size="m">
                            <b>Keine Begriffe vorhanden</b>
                            <Button onClick={() => openCreateModal()} >Neuen Begriff hinzufügen</Button>
                        </SpaceBetween>
                    </Box>
                }
                header={<Header
                    actions={<Button onClick={() => openCreateModal()} >Neuen Begriff hinzufügen</Button>}
                > Alle Begriffe & Erklärungen </Header>}
            />

            <Modal
                onDismiss={() => setVisibleCreate(false)}
                visible={visibleCreate}
                header="Neuen Begriff hinzufügen"
            >
                <form onSubmit={e => {
                    e.preventDefault()

                    if (category.value) {
                        setLoading(true)
                        DatabaseService.createTerm(term, category, explanation, abbreviation, synonyms).then(() => {
                            DatabaseService.init().then(() => {
                                setLoading(false)
                                setVisibleCreate(false)
                            })
                        })
                    }

                }}>
                    <Form
                        actions={
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button formAction="none" variant="link" onClick={e => setVisibleCreate(false)}>
                                    Cancel
                                </Button>
                                <Button disabled={loading} variant="primary">{loading ? <Spinner /> : "Save"}</Button>
                            </SpaceBetween>
                        }
                    >
                        <SpaceBetween direction="vertical" size="l">
                            <FormField label="Begriff">
                                <Input
                                    onChange={({ detail }) => setTerm(detail.value)}
                                    value={term}
                                    spellcheck
                                />
                            </FormField>
                            <FormField label="Kategorie">
                                <Select
                                    onChange={({ detail }) => setCategory(detail.selectedOption)}
                                    selectedOption={category}
                                    options={options}
                                />
                            </FormField>
                            <FormField label="Erklärung">
                                <Textarea
                                    onChange={({ detail }) => setExplanation(detail.value)}
                                    value={explanation}
                                    spellcheck
                                />
                            </FormField>
                            <FormField label="Abkürzung">
                                <Input
                                    onChange={({ detail }) => setAbbreviation(detail.value)}
                                    value={abbreviation}
                                    spellcheck
                                />
                            </FormField>
                            <FormField label="Synonyme" description="Bitte als ,-separierte Liste eintragen">
                                <Input
                                    onChange={({ detail }) => setSynonyms(detail.value)}
                                    value={synonyms}
                                    spellcheck
                                />
                            </FormField>
                        </SpaceBetween>
                    </Form>
                </form>
            </Modal>

            <Modal
                onDismiss={() => setVisibleModify(false)}
                visible={visibleModify}
                header="Begriff bearbeiten"
            >
                <form onSubmit={e => {
                    e.preventDefault()
                    if (category.value) {
                        setLoading(true)
                        DatabaseService.modifyTerm(term, category, explanation, abbreviation, synonyms, glossaryID).then(() => {
                            DatabaseService.init().then(() => {
                                setLoading(false)
                                setVisibleModify(false)
                            })
                        })
                    }

                }}>
                    <Form
                        actions={
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button formAction="none" variant="link" onClick={e => setVisibleModify(false)}>
                                    Cancel
                                </Button>
                                <Button disabled={loading} variant="primary">{loading ? <Spinner /> : "Save"}</Button>
                            </SpaceBetween>
                        }
                    >
                        <SpaceBetween direction="vertical" size="l">
                            <FormField label="Begriff">
                                <Input
                                    onChange={({ detail }) => setTerm(detail.value)}
                                    value={term}
                                    spellcheck
                                />
                            </FormField>
                            <FormField label="Kategorie">
                                <Select
                                    onChange={({ detail }) => setCategory(detail.selectedOption)}
                                    selectedOption={category}
                                    options={options}
                                />
                            </FormField>
                            <FormField label="Erklärung">
                                <Textarea
                                    onChange={({ detail }) => setExplanation(detail.value)}
                                    value={explanation}
                                    spellcheck
                                />
                            </FormField>
                            <FormField label="Abkürzung">
                                <Input
                                    onChange={({ detail }) => setAbbreviation(detail.value)}
                                    value={abbreviation}
                                    spellcheck
                                />
                            </FormField>
                            <FormField label="Synonyme" description="Bitte als ,-separierte Liste eintragen">
                                <Input
                                    onChange={({ detail }) => setSynonyms(detail.value)}
                                    value={synonyms}
                                    spellcheck
                                />
                            </FormField>
                        </SpaceBetween>
                    </Form>
                </form>
            </Modal>

            <Modal
                onDismiss={() => setVisibleDelete(false)}
                visible={visibleDelete}
                header="Begriff löschen"
            >
                <Alert
                    type="warning"
                >Willst du den Begriff "{term}" wirklich endgültig löschen? Diese Aktion kann nicht Rückgängig gemacht werden!</Alert>
                <form onSubmit={e => {
                    e.preventDefault()
                    setLoading(true)
                    DatabaseService.deleteTerm(glossaryID).then(() => {
                        DatabaseService.init().then(() => {
                            setLoading(false)
                            setVisibleDelete(false)
                        })
                    })
                }}>
                    <Form
                        actions={
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button formAction="none" variant="link" onClick={e => setVisibleDelete(false)}>
                                    Cancel
                                </Button>
                                <Button disabled={loading} variant="primary">{loading ? <Spinner /> : "Delete"}</Button>
                            </SpaceBetween>
                        }
                    >
                    </Form>
                </form>
            </Modal>

        </ContentLayout>
    );
}